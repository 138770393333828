import React from 'react'

export default function category ({
  name,
  className,
  handleCategoryChange,
  category
}) {
  return (
    <li className='block w-full'>
      <button
        className={`bg-gray-700 block w-full py-2 text-center ${className} md:text-left md:px-4 hover:bg-gray-500`}
        onClick={() => handleCategoryChange(category)}>
        {name}
      </button>
    </li>
  )
}
