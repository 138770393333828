import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import BackgroundImage from 'gatsby-background-image'
import ReactPaginate from 'react-paginate'
import Category from '../components/category/category'
import PluginStore from '../components/pluginStore/pluginStore'

import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'

import plugins from './plugins.module.css'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "backgrounds/space.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Store plugin data in 'pluginStore' state variable

  const [pluginStore, setPluginStore] = useState([
    {
      name: 'The End Skyblock',
      description:
        'The End is a dark, floating space-like dimension consisting of separate island...',
      price: 10,
      url: 'https://www.google.com',
      category: 'Skyblock'
    },
    {
      name: 'The End Factions',
      description:
        'The End is a dark, floating space-like dimension consisting of separate island...',
      price: 10,
      url: 'https://www.google.com',
      category: 'Factions'
    },
    {
      name: 'The End Misc.',
      description:
        'The End is a dark, floating space-like dimension consisting of separate island...',
      price: 10,
      url: 'https://www.google.com',
      category: 'Misc.'
    },

    {
      name: 'The End Misc.',
      description:
        'The End is a dark, floating space-like dimension consisting of separate island...',
      price: 10,
      url: 'https://www.google.com',
      category: 'Prison'
    },

    {
      name: 'The End Misc.',
      description:
        'The End is a dark, floating space-like dimension consisting of separate island...',
      price: 10,
      url: 'https://www.google.com',
      category: 'Misc.'
    }
  ])

  // Set the default page count to 8

  const [pageCount, setPageCount] = useState(
    Math.ceil(
      pluginStore.length / (pluginStore.length >= 8 ? 8 : pluginStore.length)
    )
  )

  // Hold filtered data
  const [filteredData, setFilteredData] = useState(pluginStore)

  // Set the start index for the array
  const [offset, setOffset] = useState(0)

  // Set the category
  const [category, setCategory] = useState('')

  // Control page count
  const [pageNumber, setPageNumber] = useState(0)

  // Handle page change
  const handlePageClick = data => {
    const selected = data.selected
    console.log(selected, 'selected data')
    const newOffset = Math.ceil(selected * 8)
    setOffset(newOffset)
  }

  // Handle category change and filter the data
  const handleCategoryChange = category => {
    setCategory(category)

    if (category) {
      const newFilteredData = pluginStore.filter(plugin => {
        if (plugin.category === category) return plugin
      })
      setFilteredData(newFilteredData)
      setPageCount(
        newFilteredData.length /
          (newFilteredData.length >= 8 ? 8 : newFilteredData.length)
      )
    } else {
      setFilteredData(pluginStore)
      setPageCount(
        pluginStore.length / (pluginStore.length >= 8 ? 8 : pluginStore.length)
      )
    }
    setPageNumber(1)
  }

  return (
    <Layout>
      <SEO title='Plugins' />
      <BackgroundImage
        Tag='section'
        className={`flex items-center ${plugins.sectionOne}`}
        fluid={data.background.childImageSharp.fluid}
        backgroundColor={`#040e18`}>
        <div className='container'>
          <Fade>
            <div className='text-center lg:flex lg:items-center lg:text-left'>
              <h1 className='font-bold mb-4 lg:w-1/2'>Plugins</h1>
              <p className='text-gray-400 text-sm lg:w-1/2 lg:text-right lg:text-base'>
                This biome generates as part of the outer islands of the End,
                forming the outer rims of each island, with steep cliffs below.
              </p>
            </div>
          </Fade>
        </div>
      </BackgroundImage>
      <section className={`${plugins.store} py-10 container`}>
        <div>
          <div className='flex items-center mb-4 justify-center md:justify-start'>
            <h3 className='font-bold text-lg'>Categories</h3>
            <button
              className='text-sm text-gray-400 underline ml-4 focus:outline-none hover:text-white'
              onClick={() => {
                handleCategoryChange('')
              }}>
              Reset
            </button>
          </div>
          <ul className={plugins.categories}>
            <Category
              name='Factions'
              className={`rounded-lg md:rounded-t-lg md:rounded-b-none md:mb-1 ${
                category === 'Factions' ? plugins.activeCategory : ''
              }`}
              category='Factions'
              handleCategoryChange={handleCategoryChange}
            />
            <Category
              name='Skyblock'
              className={`rounded-lg md:rounded-none md:mb-1 ${
                category === 'Skyblock' ? plugins.activeCategory : ''
              }
`}
              category='Skyblock'
              handleCategoryChange={handleCategoryChange}
            />
            <Category
              name='Prison'
              className={`rounded-lg md:rounded-none md:mb-1 ${
                category === 'Prison' ? plugins.activeCategory : ''
              }
`}
              category='Prison'
              handleCategoryChange={handleCategoryChange}
            />
            <Category
              name='Misc.'
              className={`rounded-lg md:rounded-b-lg md:rounded-t-none ${
                category === 'Misc.' ? plugins.activeCategory : ''
              }
`}
              category='Misc.'
              handleCategoryChange={handleCategoryChange}
            />
          </ul>
        </div>
        <div>
          <span className='hidden md:block md:mt-12' />
          <div className={plugins.pluginStoreContainer}>
            <PluginStore
              data={filteredData}
              startIndex={offset}
              category={category}
            />
          </div>
          <ReactPaginate
            previousLabel={
              <div
                className={`${
                  plugins.previous
                } flex items-center mr-2 flex-row-reverse`}>
                <button className='font-bold text-gray-400 text-lg bg-gray-700 w-8 h-8 rounded-lg flex justify-center flex-col items-center ml-2'>
                  <svg
                    width='9'
                    height='15'
                    viewBox='0 0 9 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      opacity='0.5'
                      d='M6.44824 14.106C6.68262 14.333 6.96094 14.4502 7.29053 14.4502C7.96436 14.4502 8.521 13.9009 8.521 13.2344C8.521 12.8975 8.37451 12.5825 8.12549 12.3335L3.3501 7.70459L8.12549 3.09033C8.38184 2.83398 8.521 2.51904 8.521 2.18945C8.521 1.52295 7.96436 0.973633 7.29053 0.973633C6.95361 0.973633 6.68262 1.0835 6.44824 1.31787L0.933105 6.70117C0.625488 7.00879 0.479004 7.32373 0.47168 7.71191C0.47168 8.09277 0.618164 8.40771 0.933105 8.72266L6.44824 14.106Z'
                      fill='white'
                      className={plugins.previousPath}
                    />
                  </svg>
                </button>
                <p className='text-white hidden md:inline-flex'>Previous</p>
              </div>
            }
            nextLabel={
              <div className={`${plugins.next} flex items-center ml-2`}>
                <button className='font-bold text-gray-400 text-lg bg-gray-700 w-8 h-8 rounded-lg flex justify-center flex-col items-center mr-2'>
                  <svg
                    width='9'
                    height='15'
                    viewBox='0 0 9 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      opacity='0.5'
                      d='M1.70215 14.4355C2.03906 14.4355 2.31006 14.3184 2.54443 14.084L8.05957 8.70801C8.37451 8.39307 8.51367 8.08545 8.521 7.69727C8.521 7.31641 8.37451 6.99414 8.05957 6.68652L2.55176 1.30322C2.31006 1.07617 2.03174 0.958984 1.70215 0.958984C1.02832 0.958984 0.47168 1.50098 0.47168 2.1748C0.47168 2.51172 0.618164 2.81934 0.867188 3.07568L5.6499 7.70459L0.867188 12.3188C0.61084 12.5752 0.47168 12.8828 0.47168 13.2197C0.47168 13.8862 1.02832 14.4355 1.70215 14.4355Z'
                      fill='white'
                      className={plugins.nextPath}
                    />
                  </svg>
                </button>
                <p className='text-white hidden md:inline-flex'>Next</p>
              </div>
            }
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={plugins.pagination}
            subContainerClassName={'pages pagination'}
            activeClassName={'font-bold text-white'}
            pageClassName={plugins.pageLink}
            forcePage={pageNumber}
          />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
