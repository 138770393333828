import React from 'react'
import category from '../category/category'

export default function pluginStore ({ data, startIndex, category }) {
  // Slice data so only 8 appear per page
  data = data.slice(startIndex, startIndex + 8)

  return data.map(({ name, description, price, url }, key) => (
    <a
      href={url}
      target='_blank'
      key={key}
      rel='noopener'
      className='bg-gray-600 block rounded-lg xl:flex xl:items-center xl:px-6 hover:bg-gray-500 active:bg-gray-700'>
      <div className='p-4 xl:p-0  xl:border-r-2 border-gray-800'>
        <h3 className='font-bold text-lg mb-2 xl:pt-4'>{name}</h3>
        <p className='opacity-75 text-sm xl:w-10/12 xl:pb-4'>{description}</p>
      </div>
      <div className='flex justify-center border-t-2 border-gray-800 xl:border-t-0 xl:ml-6'>
        <h4 className='text-xl font-bold py-2 opacity-75 xl:text-2xl'>
          ${price}
        </h4>
      </div>
    </a>
  ))
}
